<script setup>
import { ref, defineAsyncComponent } from 'vue'

const laporanPenerimaan = defineAsyncComponent(() =>
  import('@/components/laporan/Penerimaan.vue')
)

const laporanPengeluaran = defineAsyncComponent(() =>
  import('@/components/laporan/Pengeluaran.vue')
)

const laporanJurnal = defineAsyncComponent(() =>
  import('@/components/laporan/Jurnal.vue')
)

const laporanBukuBesar = defineAsyncComponent(() =>
  import('@/components/laporan/BukuBesar.vue')
)

const laporanArusKas = defineAsyncComponent(() =>
  import('@/components/laporan/ArusKas.vue')
)

const laporanLabaRugi = defineAsyncComponent(() =>
  import('@/components/laporan/LabaRugi.vue')
)

const laporanNeraca = defineAsyncComponent(() =>
  import('@/components/laporan/Neraca.vue')
)

const laporanShu = defineAsyncComponent(() =>
  import('@/components/laporan/Shu.vue')
)

let laporan = ref('penerimaan')
let hideSideBar = ref(false)

const items = ref([
  {
    label: 'Penerimaan Kas',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'penerimaan'
    },
  },
  {
    label: 'Pengeluaran Kas',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'pengeluaran'
    },
  },
  {
    label: 'Jurnal',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'jurnal'
    },
  },
  {
    label: 'Buku Besar',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'buku-besar'
    },
  },
  {
    label: 'Arus Kas',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'arus-kas'
    },
  },
  {
    label: 'Laba Rugi',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'laba-rugi'
    },
  },
  {
    label: 'Neraca',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'neraca'
    },
  },
  {
    label: 'SHU',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'shu'
    },
  },
])

function onToggle() {
  hideSideBar.value = !hideSideBar.value
}
</script>

<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Laporan Keuangan</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Keuangan / Laporan</span>
      </div>
    </div>
    <div class="grid">
      <div
        class="col-12 md:col-3"
        :class="{
          hidden: hideSideBar,
          'transition-duration-500': hideSideBar,
        }"
      >
        <Menu :model="items" class="menu-laporan w-full border-none" />
      </div>
      <div
        class="card col-12 md:col-9"
        :class="{
          'md:col-12': hideSideBar,
          'transition-duration-200': hideSideBar,
        }"
      >
        <div>
          <laporanPenerimaan
            v-if="laporan === 'penerimaan'"
            @toggle="onToggle"
          />
          <laporanPengeluaran
            v-if="laporan === 'pengeluaran'"
            @toggle="onToggle"
          />
          <laporanJurnal v-if="laporan === 'jurnal'" @toggle="onToggle" />
          <laporanBukuBesar
            v-if="laporan === 'buku-besar'"
            @toggle="onToggle"
          />
          <laporanArusKas
            v-if="laporan === 'arus-kas'"
            @toggle="onToggle"
          />
          <laporanLabaRugi
            v-if="laporan === 'laba-rugi'"
            @toggle="onToggle"
          />
          <laporanNeraca
            v-if="laporan === 'neraca'"
            @toggle="onToggle"
          />
          <laporanShu
            v-if="laporan === 'shu'"
            @toggle="onToggle"
          />
        </div>
      </div>
    </div>
  </div>
</template>
